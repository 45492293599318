<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">

    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper ps-0">

    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
        <li class="language-nav">

        </li>

        <li class="onhover-dropdown">
          <div class="notification-box"><i data-feather="bell"> </i><span class="badge rounded-pill badge-secondary">0</span></div>
          <ul class="notification-dropdown onhover-show-div">
            <li><i data-feather="bell"></i>
              <h6 class="f-18 mb-0">Notitications</h6>
            </li>

          </ul>
        </li>

        <li>
          <div class="mode"><i class="fa fa-moon-o"></i></div>
        </li>

        <li class="maximize">
          <a class="text-dark" onclick="javascript:toggleFullScreen()">
          <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li>
        <li class="profile-nav onhover-dropdown p-0 me-0">
          <div class="media profile-media"><img style="height: 35px;" class="b-r-10" src="../assets/images/user.jpg" alt="">
            <div class="media-body"><span>{{firsname}} {{lastname}}</span>
              <p class="mb-0 font-roboto">Administrateur <i class="middle fa fa-angle-down"></i></p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li><a >
              <app-feather-icons [icon]="'user'"></app-feather-icons>
              <span>Profil</span>
            </a></li>
            <li><a (click)="logout()" ><app-feather-icons [icon]="'log-in'"></app-feather-icons>
                 <span>Sortie</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

  </div>
</div>
