

import {Component, OnInit} from "@angular/core";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public today: number = Date.now();

  loadScript(url:any) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit(): void {

    this.loadScript('../../../../assets/js/jquery-3.5.1.min.js');
    this.loadScript('../../../../assets/js/bootstrap/bootstrap.bundle.min.js');
    this.loadScript('../../../../assets/js/icons/feather-icon/feather.min.js');
    this.loadScript('../../../../assets/js/icons/feather-icon/feather-icon.js');
    this.loadScript('../../../../assets/js/scrollbar/simplebar.js');
    this.loadScript('../../../../assets/js/scrollbar/custom.js');
    this.loadScript('../../../../assets/js/config.js');
    //this.loadScript('../../../../assets/js/sidebar-menu.js');
    this.loadScript('../../../../assets/js/chart/chartist/chartist.js');

    this.loadScript('../../../../assets/js/chart/knob/knob.min.js');
    this.loadScript('../../../../assets/js/chart/knob/knob-chart.js');
    this.loadScript('../../../../assets/js/chart/apex-chart/apex-chart.js');
    this.loadScript('../../../../assets/js/chart/apex-chart/stock-prices.js');
    this.loadScript('../../../../assets/js/notify/bootstrap-notify.min.js');

    this.loadScript('../../../../assets/js/datepicker/date-picker/datepicker.js');
    this.loadScript('../../../../assets/js/datepicker/date-picker/datepicker.en.js');
    this.loadScript('../../../../assets/js/datepicker/date-picker/datepicker.custom.js');
    this.loadScript('../../../../assets/js/typeahead/handlebars.js');
    this.loadScript('../../../../assets/js/typeahead/typeahead.bundle.js');
    this.loadScript('../../../../assets/js/typeahead/typeahead.custom.js');
    this.loadScript('../../../../assets/js/typeahead-search/handlebars.js');
    this.loadScript('../../../../assets/js/typeahead-search/typeahead-custom.js');
    this.loadScript('../../../../assets/js/tooltip-init.js');
    this.loadScript('../../../../assets/js/script.js');
  }

}
