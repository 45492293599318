
<div class="loader-wrapper" style="margin: auto" *ngIf="loaderService.loading$ | async">
  <!--<div class="loader-index"><span></span></div>
  <svg>
    <defs></defs>
    <filter id="goo">
      <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
      <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"> </fecolormatrix>
    </filter>
  </svg>-->

  <div class="loader">
    <div class="m-t-30">
      <img
        src="../../../assets/images/loader-cube.gif"
        width="100"
        height="100"
        alt="loader"
      />
    </div>
    <p style="text-align: center;">Chargement...</p>
  </div>
</div>



