import {Component, Input, OnInit, Output, EventEmitter, Inject} from "@angular/core";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {KeycloakService} from "keycloak-angular";
import {UserKeycloackModel} from "../../../admin/configuration/models/userKeycloack";
import {KeycloakProfile} from "keycloak-js";
import {AbstractComponent} from "../abstract-component";
import {NavService} from "../../services/nav.service";
import {LayoutService} from "../../services/layout.service";
import {DOCUMENT} from "@angular/common";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss']
})
export class HeaderComponent extends AbstractComponent<any> implements OnInit {

  user = new UserKeycloackModel();

  public isLoggedIn = false;
  public userProfile!: KeycloakProfile;

  firsname: string = "";
  lastname: string = "";

  auth!: any;




  constructor( private router:Router,
               private modalService:NgbModal,
               public layout: LayoutService,
               public navServices: NavService,
               @Inject(DOCUMENT) private document: any,
               private readonly keycloakService: KeycloakService) {
    super();
  }




  async ngOnInit() {

    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isLoggedIn) {
      this.userProfile = await this.keycloakService.loadUserProfile();
      if(this.userProfile){
        this.firsname = this.userProfile.firstName;
        this.lastname = this.userProfile.lastName;
      }
      //this.user.name = this.userProfile.firstName;
      window.sessionStorage.setItem('userdetails', JSON.stringify(this.user));
    }

  }

  logout() {
    this.keycloakService.logout();
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu  = false;
    this.navServices.levelMenu = false;
  }




}
