import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {KeycloakLoginOptions, KeycloakProfile, KeycloakTokenParsed} from 'keycloak-js';
import {Role} from "../../shared/models/misc/role.enum";
declare let Keycloak: any;

@Injectable()
export class AuthService {
  static auth: any = {};
  constructor(private keycloakService: KeycloakService) {}

  roles!: string[];
  private role: string;

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance()
        .idTokenParsed;
      return userDetails;
    } catch (e) {
      console.error('Exception', e);
      return undefined;
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public login(): void {
    this.keycloakService.login();
  }

  public logout(): void {
    this.keycloakService.logout(window.location.origin);
  }

  public redirectToProfile(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public async getAccessToken(): Promise<string> {
    return await this.keycloakService.getToken();
  }


  public getRoleByTokens(): string {
    if(this.getRoles().includes("ADMIN") ){
      this.role = Role.ROOT;
    }

    if(this.getRoles().includes("AGENT")){
      this.role = Role.AGENT;
    }

    return this.role;

  }
}
