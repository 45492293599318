// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import keycloakConfig from "./keycloak.config";

export const environment = {
  /*baseUrl: 'http://82.165.212.140:8060/core-service/admin/v1/',
  localUrl: 'http://82.165.212.140:8060/transaction-service/admin/v1/',
  basePublicUrl: 'http://82.165.212.140:8060/core-service/public/v1/',
  url: 'http://82.165.212.140:8060/core-service/',
  secureUrl: 'http://82.165.212.140:8060/core-service/secured/v1/',
  moneyurl: 'http://82.165.212.140:8060/money-service/',*/
  providerurl: 'https://proxy.sendrate.ca:8060/provider-service/admin/',
  baseUrl: 'https://proxy.sendrate.ca:8060/core-service/admin/v1/',
  localUrl: 'https://proxy.sendrate.ca:8060/transaction-service/admin/v1/',
  basePublicUrl: 'https://proxy.sendrate.ca:8060/core-service/public/v1/',
  url: 'https://proxy.sendrate.ca:8060/core-service/',
  secureUrl: 'https://proxy.sendrate.ca:8060/core-service/secured/v1/',
  moneyurl: 'https://proxy.sendrate.ca:8060/money-service/',
  /*localUrl: 'http://localhost:8060/transaction-service/admin/v1/',
  baseUrl: 'http://localhost:8060/core-service/admin/v1/',
  basePublicUrl: 'http://localhost:8060/core-service/public/v1/',
  url: 'http://localhost:8060/core-service/',
  secureUrl: 'http://localhost:8060/core-service/secured/v1/',
  moneyurl: 'http://localhost:8060/money-service/',*/
  keycloak: keycloakConfig,
  production: false,
};
